import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "./config";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { convertCurrencyLocation } from "./helpers";
import { Loading } from "./Components/Loading";
import { AiOutlineClose } from "react-icons/ai";

@inject("AppStore")
@observer
class Model extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div
        className={`modelSelector__item animate__animated animate__zoomIn ${
          this.props.store.selectedModel &&
          this.props.store.selectedModel.id === this.props.model.id &&
          "selected"
        }`}
        style={{
          textAlign: "center",
          backgroundImage: `url('${
            appConfig.api + this.props.model.cover.url
          }')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={() => {
          this.props.store.selectModel(this.props.model);
        }}
      >
        <div className="modelSelector__content">
          <div className="modelSelector__price">
            <div className="modelSelector__content_title">
              From*{" "}
              {convertCurrencyLocation(
                this.props.model.starting_from,
                this.store.location,
                false
              )}
            </div>
          </div>
          <div className="modelSelector__name">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

@observer
class Tabs extends React.Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    // console.log(this.props.items);
  }

  selectTab(i) {
    if (i == this.state.selected) {
      this.setState({ selected: -1 });
    } else {
      this.setState({ selected: i });
    }
  }

  render() {
    const { model } = this.props;

    if (!model) return <React.Fragment />;

    return (
      <div className="selectedModel__tabs">
        {model.tabs &&
          model.tabs.map((x, i) => (
            <div
              className={`selectedModel__tabs--item ${
                this.state.selected == i ? "selected" : ""
              }`}
              key={i}
            >
              <div
                className="selectedModel__tabs--title"
                onClick={() => this.selectTab(i)}
              >
                {" "}
                {this.state.selected == i ? (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="tabs-icon"
                    color={model.color}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="tabs-icon"
                    color={model.color}
                  />
                )}
                {x[0]}
              </div>
              <div
                className={`selectedModel__tabs--content ${
                  this.state.selected == i
                    ? "animate__animated animate__fadeIn"
                    : ""
                }`}
              >
                <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
              </div>
            </div>
          ))}
      </div>
    );
  }
}

@inject("AppStore")
@observer
class Select extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { location } = this.props.match.params;

    this.store.getLocation("default");
    this.store.loadConfig();
    this.store.getModels();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a href={`/`}>
              {this.store.location && (
                <img
                  src={`${appConfig.api}${this.store.location.logo.url}`}
                  className="logo"
                  alt=""
                />
              )}
            </a>
          </div>
        </div>
        <div className={`content ${this.store.selectedModel && "open"}`}>
          <div
            className="modelSelector__left"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div className="modelSelector__title">
                SELECT YOUR <br class="m-only" />{" "}
                <strong>{appConfig.locale.name}</strong>
              </div>
              <div className="modelSelector__titleSlogan">
                {this.store.configuration.slogan}
              </div>
            </div>
            <div className="modelSelector__items">
              {this.store.list.map((x) => (
                <Model key={x.id} model={x} store={this.store} />
              ))}
            </div>
          </div>

          {this.store.selectedModel && (
            <div
              id="content"
              className="modelSelector__rightBackdrop"
              onClick={(e) => {
                this.store.selectedModel = undefined;
              }}
            >
              <div
                className={`modelSelector__right ${
                  this.store.selectAnimate
                    ? "animate__animated animate__fadeInRight"
                    : ""
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="modelSelector__right--close"
                  onClick={(e) => {
                    this.store.selectedModel = undefined;
                  }}
                >
                  <AiOutlineClose fontSize="32" />
                </div>
                <div
                  className="selectedModel__title"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: this.store.selectedModel.name,
                  }}
                ></div>
                <div className="selectedModel__image" style={{ marginTop: 10 }}>
                  <img
                    src={appConfig.api + this.store.selectedModel.cover.url}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div className="selectedModel__tech">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      TECHNICAL SPECIFICATIONS
                    </div>
                  </div>

                  {this.store.selectedModel.standard_spec && (
                    <div className="selectedModel__specs">
                      {this.store.selectedModel.standard_spec.length_m && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">Length</div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.length_m}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.beam && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">Beam</div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.beam}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.beam_opened && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Beam with open Side Teracces
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.beam_opened}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.draft && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">Draft</div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.draft}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.displacement && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Displacement
                          </div>
                          <div className="selectedModel__spec--text">
                            {
                              this.store.selectedModel.standard_spec
                                .displacement
                            }
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.passengers && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Passengers
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.passengers}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.berths && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">Berths</div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.berths}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.fuel && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Fuel Cap.
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.fuel}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.range && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">Range</div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.range}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec.water && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Fresh Water Cap.
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec.water}
                          </div>
                        </div>
                      )}

                      {this.store.selectedModel.standard_spec
                        .classification && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Classification
                          </div>
                          <div className="selectedModel__spec--text">
                            {
                              this.store.selectedModel.standard_spec
                                .classification
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="selectedModel__engines">
                    <div className="selectedModel__subtitle">
                      <div
                        className="selectedModel__subtitle--top"
                        style={{
                          borderColor: `${this.store.selectedModel.color}`,
                        }}
                      >
                        ENGINE TYPES
                      </div>
                    </div>

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine1_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine2_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine3_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine4_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                </div>

                {this.store.selectedModel.extra_features && (
                  <div className="selectedModel__standard">
                    <div className="selectedModel__subtitle">
                      <div
                        className="selectedModel__subtitle--top"
                        style={{
                          borderColor: `${this.store.selectedModel.color}`,
                        }}
                      >
                        STANDARD FEATURES
                      </div>
                    </div>
                    <div
                      className="selectedModel__standardspecs"
                      style={{ paddingTop: 0 }}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: this.store.selectedModel.extra_features,
                        }}
                      ></pre>
                    </div>
                  </div>
                )}

                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      STANDARD SPECIFICATIONS
                    </div>
                  </div>
                  <div className="selectedModel__standardspecs">
                    <Tabs model={this.store.selectedModel} />
                    <div
                      style={{ fontSize: 12, marginTop: 35, marginBottom: 100 }}
                    >
                      Specifications are based on available information at the
                      time. Actual specifications may vary.
                      {appConfig.locale.name} reserves the right to change
                      product line specifications, equipment, color scheme and
                      pricing, or to discontinue models at any time without
                      notice or obligation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer modelSelector__footer">
          <div className="modelSelector__footer--left">
            <div style={{ fontSize: 12, paddingRight: 10, paddingLeft: 10 }}>
              *Manufacturer’s Suggested Retail Price (MSRP), excludes options,
              taxes, title and registration processing.
            </div>
          </div>
          <div className="modelSelector__footer--right">
            {this.store.selectedModel && (
              <div className="modelSelector__cleanbutton blue">
                <a href={`/build/${this.store.selectedModel.slug}`}>NEXT</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Select;
