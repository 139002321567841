import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
// import { ZohoForm } from "./ZohoForm";

@inject("AppStore")
@observer
class CompleteStep extends React.Component {
  store = this.props.AppStore;

  render() {
    const { store } = this.props;

    return (
      <div className="step-complete-content">
        <div
          className="image"
          style={{
            display: "none",
            backgroundImage:
              store.data.picture &&
              `url(${appConfig.api}${store.data.picture.url})`,
          }}
        ></div>
        <div className="complete-content">
          {store.page.completionType === "pdf" &&
            (store.page.lang === "en" ? (
              <React.Fragment>
                {/* <ZohoForm data={store.summary} /> */}

                <div>
                  <h2>PLEASE CHECK YOUR INBOX!</h2>
                  <h3>Dear {store.detailsForm.firstname}</h3>
                  <p className="text">
                    Thank you for building your{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: store.data.name }}
                    ></span>
                  </p>
                  <p className="text">
                    The Summary PDF has been delivered and is waiting for you in
                    your inbox.
                  </p>
                  <p className="text">
                    If you have any questions after reviewing our Summary PDF,
                    please kindly respond to our email and the{" "}
                    {store.location.brand} Team will contact you shortly.
                  </p>
                  <p className="text">
                    Alternatively, you may simply phone us and provide your
                    Configuration ID Number (CIN: {store.page.order_number}) so
                    that we can create your preferred{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: store.data.name }}
                    ></span>
                    .
                  </p>
                  <p className="text">
                    We look forward to building your stunning new{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: store.data.name }}
                    ></span>
                  </p>
                  <p className="text">Thank you</p>
                  <p>Team {store.location.brand}</p>
                  <div style={{ textAlign: "center" }}>
                    <div className="modelSelector__button dark">
                      <a href={`/`}>BACK TO HOME PAGE</a>
                    </div>
                  </div>
                </div>

                <div style={{ display: "none" }}>
                  <h2>CHECK YOUR INBOX!</h2>
                  <h3>
                    A PDF OF YOUR{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: store.data.name }}
                    ></span>{" "}
                    WILL BE WAITING FOR YOU.
                  </h3>
                  <h3>#{store.page.order_number}</h3>
                  <p className="text">
                    Once you have received the PDF of your build and have any
                    questions simple reply to the email and a{" "}
                    {appConfig.locale.name} team member will respond within an
                    hour.
                  </p>
                  <p className="text-bold">
                    Thank you for your enquiry, we look forward to building your
                    beautiful new {store.location.brand}.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <div className="modelSelector__button dark">
                      <a href={`/`}>BACK TO HOME PAGE</a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h2>E-POSTA KUTUNUZU KONTROL EDİN!</h2>
                <h3>
                  TASARLADIĞINIZ {store.data.name} MODEL PDF'İ SİZİ BEKLİYOR.
                </h3>
                <h3>#{store.page.order_number}</h3>
                <p className="text">
                  Size ulaştırılan PDF mailine, sorularınızı iletebilirsiniz.
                  {appConfig.locale.name} ekibimiz en kısa sürede sorularınıza
                  yanıt verecektir.
                </p>
                <p className="text-bold">
                  İlginiz için teşekkür ederiz. Yeni model SaxDor teknelerimiz
                  için web sitemizi ziyaret edebilirsiniz.
                </p>
                <div>
                  <a
                    href={appConfig.locale.url}
                    className="button black-button wide"
                  >
                    ANASAYFA
                  </a>
                </div>
              </React.Fragment>
            ))}
          {store.page.completionType === "payment" && (
            <React.Fragment>
              <div>
                <h2>CHECK YOUR INBOX!</h2>
                <h3>Dear {store.detailsForm.firstname}</h3>
                <p className="text">
                  YOUR{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: store.data.name }}
                  ></span>{" "}
                  HAS BEEN ORDERED!
                </p>
                <p className="text">
                  Summary PDF has been sent and waiting in your Inbox.
                </p>
                <p className="text">
                  Once you have reviewed our PDF and have any questions, kindly
                  reply to our email and {store.location.brand} Team will get in
                  touch with you in a short while.
                </p>
                <p className="text">
                  Alternatively, you can simply give us a call and share your
                  Configuration ID Number (CIN : {store.page.order_number}) to
                  build your desired XLV.
                </p>
                <p className="text">
                  We look forward to building your beautiful new{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: store.data.name }}
                  ></span>
                </p>
                <p className="text">Thank you</p>
                <p>Team {store.location.brand}</p>
                <div style={{ textAlign: "center" }}>
                  <div className="modelSelector__button dark">
                    <a href={`/`}>BACK TO HOME PAGE</a>
                  </div>
                </div>
              </div>

              <div style={{ display: "none" }}>
                <h2>SUCCESS!</h2>
                <h3>
                  YOUR{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: store.data.name }}
                  ></span>{" "}
                  HAS BEEN ORDERED!
                </h3>
                <h3>#{store.page.order_number.order_number}</h3>
                <p className="text">
                  You will be receiving an email from your Personal{" "}
                  {store.location.brand}
                  Sales Representative, with your Purchase agreement as well as
                  more information on what to expect next.
                </p>
                <p className="text-bold">
                  Thanks for your trust and we look forward to building your
                  beautiful new {store.location.brand}.
                </p>

                <div className="modelSelector__button dark">
                  <a href={`/`}>BACK TO HOME PAGE</a>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default CompleteStep;
